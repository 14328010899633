import React, { createContext, useState } from "react";
import {
    capitalize,
    reduceString,
} from "../resources/Deprecated/stringFormatter";
import { useHistory } from "react-router-dom";

export const BreadcrumbContext = createContext(null);

const BreadCrumbProvider = ({ children }) => {
    const history = useHistory();
    const [crumbs, setCrumbs] = useState({});

    /**
     * Handles route change event, routes the user to a new URL, and manages breadcrumb display.
     *
     * @throws {string} Throws an error if required properties are missing in the page object.
     *
     * @function
     * @memberof BreadCrumbProvider
     * @name handleRouteChange
     *
     * @param {Object} pageObj - The object containing information for routing and breadcrumb display.
     * @param {string} pageObj.uri - The URL to which the user will be routed.
     * @param {Array<Object>} pageObj.breadcrumbInfo - An array of objects representing breadcrumb items.
     * @param {string} pageObj.breadcrumbInfo[].id - The unique identifier for the breadcrumb item.
     * @param {string} pageObj.breadcrumbInfo[].text - The text to be displayed for the breadcrumb item.
     *
     * @throws {string} Throws an error if required properties are missing in the page object.
     *
     * @example
     * const pageObj = {
     *   uri: '/example/1/test/2',
     *   breadcrumbInfo: [
     *     { id: '1', text: 'First Crumb' },
     *     { id: '2', text: 'Second Crumb' },
     *   ],
     * };
     */
    const handleRouteChange = (pageObj) => {
        const requiredFields = ["uri", "breadcrumbInfo"];
        const requiredBreadCrumbFields = ["id", "text"];

        for (const field of requiredFields) {
            if (!pageObj[field]) {
                throw `ActionButtons Component missing required prop '${field}' for viewPage object.`;
            }
        }

        if (pageObj.breadcrumbInfo.length === 0) {
            throw "ActionButtons Component requires at least one item in 'breadcrumbInfo' for viewPage object.";
        }

        for (const dis of pageObj.breadcrumbInfo) {
            for (const field of requiredBreadCrumbFields) {
                if (!dis[field]) {
                    throw `ActionButtons Component missing required prop '${field}' in 'breadcrumbInfo' for viewPage object.`;
                }
            }
        }

        //? Allows the user to add multiple Crumb displays
        pageObj.breadcrumbInfo.forEach((c) => {
            _addCrumb(c.id, c.text);
        });

        history.push(pageObj.uri);
    };

    /**
     * Adds a breadcrumb to the key-value pair for display purposes.
     *
     * @param {string} key - The key associated with the breadcrumb that maps to a URL
     * @param {string} value - The text to be displayed for the breadcrumb.
     *
     * @function
     * @memberof BreadCrumbProvider
     * @name _addCrumb - Privately used within BreadCrumbProvider.jsx
     */
    const _addCrumb = (key, value) => {
        setCrumbs((prevCrumbs) => ({
            ...prevCrumbs,
            [key]: value,
        }));
    };

    /**
     * Fetches a breadcrumb object based on the provided key, applying text transformations.
     * Capitalizes first char in text string, reduces string to max of 20 char.
     *
     * @param {string} key - The key associated with the desired breadcrumb.
     * @returns {Object} The breadcrumb object containing a URL identifier (id) and transformed text (text).
     *
     * @function
     * @memberof BreadCrumbProvider
     * @name fetchCrumb
     */
    const fetchCrumb = (key) => {
        //? An improvement in the future to handle a lost crumb could be dynamically parsing the URL
        //? and grabbing the current context of where we are and returning currentX.
        //? Ex: CurrentCustomer; CurrentJob; CurrentEvent
        //? Ex: /customer/.../job/...
        const text = reduceString(crumbs[key] ?? key, 20);

        return {
            id: key,
            text: capitalize(text),
        };
    };

    const values = {
        fetchCrumb,
        handleRouteChange,
    };

    return (
        <BreadcrumbContext.Provider value={values}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export default BreadCrumbProvider;
