import React, { useContext, useState } from "react";
import SchedulerProvider, {
    SchedulerContext,
} from "../../../providers/Deprecated/Customer/SchedulerProvider.jsx";
import { SchedulerToolbar } from "./Components/SchedulerToolbar.jsx";
import { SchedulerGrid } from "./Components/SchedulerGrid.jsx";
import dayjs from "dayjs";

const WrappedSchedulerPage = () => {
    return (
        <SchedulerProvider>
            <SchedulerPage />
        </SchedulerProvider>
    );
};
const SchedulerPage = () => {
    const schedulerContext = useContext(SchedulerContext);
    const [date, setDate] = useState(
        dayjs().startOf("week").add(1, "day").toDate()
    );
    const [schedulerWindow, setSchedulerWindow] = useState("work-week");
    const [numOfDays, setNumOfDays] = useState(5);

    /**
     * @function onDateChange
     * @description Takes in Date Change Event and sets event accordingly
     * @param {Date} newDate - new JS date for starting scheduler view
     */
    const onDateChange = (newDate) => {
        const newUtcDate = dayjs(newDate).tz("utc").toDate();
        setDate(newUtcDate);
        schedulerContext.setCurrentDate(newDate);
    };

    const onSchedulerWindowChange = (newSchedulerWindow) => {
        setSchedulerWindow(newSchedulerWindow);
        setNumOfDays(newSchedulerWindow);
    };

    return (
        <>
            <SchedulerToolbar
                startDate={date}
                schedulerWindow={schedulerWindow}
                onDateChange={onDateChange}
                onSchedulerWindowChange={onSchedulerWindowChange}
                numOfDays={numOfDays}
            />
            <SchedulerGrid
                events={schedulerContext.events}
                startDate={date}
                numOfDays={numOfDays}
            />
        </>
    );
};

export default WrappedSchedulerPage;
